import { ErrorPage404 } from "~/components/ErrorPage";
import { wrapGetStaticProps } from "~/lib/server/wrapGetProps";

export const getStaticProps = wrapGetStaticProps(() => ({
  props: {
    meta: { piano: { type: "simple" } },
  },
}));

export default ErrorPage404;
